import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
	hidden:true,
    component: ()=>import('../views/Login.vue')
  },
  {	
	  path: '/home',
	  name: '门店管理',
	  redirect:'/welcome',
	  index:'1',
	  GRADE:0,
	  GRADES:1,
	  component: ()=>import('../views/Home.vue'),
	  children:[
		  {
			 path:'/welcome',
			 name:'欢迎页',
			 hidden:true,
			 component:()=>import('../components/Welcome.vue')
		  }
		  ,
		  {
			  path:'/shoplist',
			  name:'门店列表',
			  component:()=>import('../components/shoplist/shoplist.vue')
		  }
	  ]
  },
  {
  	  path: '/dls',
  	  name: '代理商管理',
  	  redirect:'/welcome',
	  index:'2',
	  GRADE:99,
	  GRADES:1,
  	  component: ()=>import('../views/Home.vue'),
  	  children:[
  		  {
  			 path:'/welcome',
  			 name:'欢迎页',
  			 hidden:true,
  			 component:()=>import('../components/Welcome.vue')
  		  }
  		  ,
  		  {
  			  path:'/dlslist',
  			  name:'代理商列表',
  			  component:()=>import('../components/dlslist/dlslist.vue')
  		  }
  	  ]
  },
  {
  	  path: '/rpt',
  	  name: '数据统计',
  	  redirect:'/welcome',
  	  index:'3',
	  GRADE:0,
	  GRADES:1,
  	  component: ()=>import('../views/Home.vue'),
  	  children:[
  		  {
  			 path:'/welcome',
  			 name:'欢迎页',
  			 hidden:true,
  			 component:()=>import('../components/Welcome.vue')
  		  }
  		  ,
  		  {
  			  path:'/rpt',
  			  name:'库存统计',
  			  component:()=>import('../components/rpt/kcrpt.vue')
  		  },
		  {
			  path:'/xsrpt',
			  name:'销售统计',
			  component:()=>import('../components/rpt/xsrpt.vue')
		  },
		  {
			  path:'/viprpt',
			  name:'会员统计',
			  component:()=>import('../components/rpt/viprpt.vue')
		  }
  	  ]
  }
]

const router = new VueRouter({
  routes
})


// 路由导航守卫

router.beforeEach((to,from,next)=>{
	if(to.path == '/'){
		next()
	} else {
		let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
		const token = getUserSession.token;
		
		if(!token){
			next('/')
		} else {
			next()
		}
	}
})


export default router
